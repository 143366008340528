<template>
    <img id="close-btn" class="modal-hover-highlight" src="../assets/images/close.svg" @click="closeClicked">
    <img id="prev-btn" class="modal-hover-highlight" src="../assets/images/chev_left.svg" v-if="hasPrev" @click="prevClicked">
    <img id="next-btn" class="modal-hover-highlight" src="../assets/images/chev_right.svg" v-if="hasNext" @click="nextClicked">
    <div id="modal-content-parent">
        <div id="modal-content">
            <iframe id="embed" :src="currentMovie.embedURL" width="100%" height="100%"></iframe>
        </div>
    </div>
</template>

<script>
import movies from '../assets/data/movies'

export default {
    name: 'movies-modal',
    title: 'Movies',
    props: {
        movieId: Number, // the initial movie id, might change due to navigation
    },
    data() {
        return {
            currentMovieId: this.movieId
        }
    },
    movies: movies,
    methods:  {
        closeClicked() {
            this.$router.push('/movies')
        },
        prevClicked() {
            this.currentMovieId -= 1
            this.$router.push(`/movies/${this.currentMovieId}`)
        },
        nextClicked() {
            this.currentMovieId += 1
            this.$router.push(`/movies/${this.currentMovieId}`)
        },
    },
    computed: {
        hasPrev() {
            return this.currentMovieId > 0
        },
        hasNext() {
            return this.currentMovieId < movies.length-1
        },
        currentMovie() {
            return movies[this.currentMovieId]
        }
    },
    mounted() {
        document.body.style.overflow = 'hidden'
    },
    unmounted() {
        document.body.style.overflow = 'scroll'
    },
}
</script>

<style lang="scss" scoped>

@import '@/scss/modal';
@include modal-components;

#modal-content-parent #modal-content {
    margin: auto;
    overflow: hidden;

    #embed {
        width: 70vw;
        height: 47vw;
    }
}

@media screen and (min-width: $tabletWidth) {

    #modal-content-parent #modal-content {
        // copied from tablet card
        background: white;
        border-radius: 30px;
        border: 7px solid $borderColor;
        box-shadow: 4px 4px $shadowColor;

        #embed {
            width: 70vw;
            height: 47vw;
        }
    }
}

</style>
export default [
    {
        name: "ダダダダ天使",
        cover: "https://i.ytimg.com/vi/jcE4Pd0Yxbc/maxresdefault.jpg",
        embedURL: "https://www.youtube.com/embed/jcE4Pd0Yxbc",
    },
    {
        name: "おねがいマッスル",
        cover: "https://i.ytimg.com/vi/c9Exc0zzsRU/maxresdefault.jpg",
        embedURL: "https://www.youtube.com/embed/c9Exc0zzsRU",
    },
    {
        name: "Tulip",
        cover: "https://i.ytimg.com/vi/h6vKx1mygag/hqdefault.jpg",
        embedURL: "https://www.youtube.com/embed/h6vKx1mygag",
    },
    {
        name: "勾指起誓",
        cover: "https://i.ytimg.com/vi/znnNgU5p_GM/hqdefault.jpg",
        embedURL: "https://www.youtube.com/embed/znnNgU5p_GM",
    },
    {
        name: "グランドエスケープ",
        cover: "https://i.ytimg.com/vi/ER26yjAxpYY/hqdefault.jpg",
        embedURL: "https://www.youtube.com/embed/ER26yjAxpYY",
    },
    {
        name: "五等分の気持ち",
        cover: "https://i.ytimg.com/vi/-vfa5IrYCE0/hqdefault.jpg",
        embedURL: "https://www.youtube.com/embed/-vfa5IrYCE0",
    },
    {
        name: "你的猫咪(君の猫)",
        cover: "https://i.ytimg.com/vi/xpiAxt7YSIw/maxresdefault.jpg",
        embedURL: "https://www.youtube.com/embed/xpiAxt7YSIw",
    },
]